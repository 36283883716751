/**
 * Date/Time formatter
 *
 * @param {Date} date
 * @param {boolean} [full]
 */
module.exports = function(date, full = false) {
  try {
    if (full)
      return new Intl.DateTimeFormat('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date);

    return new Intl.DateTimeFormat('de-DE', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  } catch (err) {
    console.log(err);
    return '';
  }
};
