// @ts-check
/**
 * E-Mail Validation, taken from https://stackoverflow.com/a/46181
 * @param {string} email
 * @return {boolean}
 */
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Detect url in text strings and converts them to a-tag
 *
 * @param {string} text The raw message text
 *
 * @return {string} The message text with each link replaced
 */
function urlify(text) {
  var urlRegex = /(((https?:\/\/)(www\.)?|(www\.))[A-Za-z0-9äöüÄÖÜ\-]+\.[A-Za-z0-9äöüÄÖÜ\?=\+&\-\.\/\;]+[A-Za-z0-9äöüÄÖÜ\?=\+&\-\/\;]+)/g;

  return text.replace(urlRegex, (url, b, c) => {
    const newUrl = url.indexOf('http') === 0 ? url : `http://${url}`;

    return `<a href="${newUrl}" target="_blank" style="color:#fff;text-decoration:underline;" title="${url}">${url
      .replace('http://', '')
      .replace('https://', '')
      .substr(0, 30)}${
      url.replace('http://', '').replace('https://', '').length > 30 ? '...' : ''
    }</a>`;
  });
}

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
// @ts-ignore
const isIE = () => !!window.MSInputMethodContext && !!document.documentMode;
const isEdge = () => !isIE() && !!window.StyleMedia;

function supportsJitsi() {
  return !(isSafari() || isIE() || isEdge());
}


module.exports = {
  validateEmail,
  urlify,
  supportsJitsi
};
