// @ts-check

/**
 * Adds a message to local messages object
 * @param {Object} params
 * @param {string} params.target The socket id of the message target
 * @param {{[key: string]: any}} params.messages
 * @param {string} params.message
 * @param {Date} params.date
 * @param {boolean} params.self
 * @param {string} params.sendToName
 * @param {string} params.shownName
 * @param {string} [params.sendFromName]
 * @param {string} [params.avatar]
 */
export function saveMessage({
  target,
  messages,
  message,
  date,
  self,
  sendToName,
  shownName,
  sendFromName,
  avatar
}) {
  if (target in messages && typeof messages[target] !== 'undefined') {
    messages[target].messages.push({
      self: self,
      time: date,
      message: message,
      senderName: sendFromName,
      shownName: shownName,
      avatar: avatar
    });
  } else {
    messages[target] = {
      name: sendToName,
      messages: []
    };
    messages[target].messages.push({
      self: self,
      time: date,
      message: message,
      senderName: sendFromName,
      shownName: shownName,
      avatar: avatar
    });
  }
}
