import Features from '../../config/features.js';

let videoChatUrl = 'https://jitsi.ws-chat.de/';
if (Features.features.videoChatUrl) {
  console.log(Features.features.videoChatUrl);
  videoChatUrl = Features.features.videoChatUrl;
}

export function getNewVideo(streamId, io) {
  window.open(`${videoChatUrl}${streamId}`, '_blank');
  // console.log('https://video.ws-chat.de/?' + 'streamId=' + streamId);
  // var socket = io.connect('https://video.ws-chat.de', {
  //   query: 'streamId=' + streamId
  // });

  // const containerId = 'users';

  // const container = document.createElement('div');
  // container.setAttribute('id', containerId);

  // container.setAttribute(
  //   'style',
  //   'position: absolute; width: 100%; height: 250px; top: 0; left: 0'
  // );

  // if (document.querySelector('#ws-chat-app-1')) {
  //   // frontend
  //   document
  //     .querySelector('#ws-chat-app-1')
  //     .querySelector('.box-body')
  //     .appendChild(container);
  // } else if (document.querySelector('.ws-chat-app')) {
  //   // backend
  //   document
  //     .querySelector('.ws-chat-app')
  //     .querySelector('.box-body')
  //     .appendChild(container);
  // }

  // var answersFrom = {};
  // var peerConnection =
  //   window.RTCPeerConnection ||
  //   window.mozRTCPeerConnection ||
  //   window.webkitRTCPeerConnection ||
  //   window.msRTCPeerConnection;

  // var sessionDescription =
  //   window.RTCSessionDescription ||
  //   window.mozRTCSessionDescription ||
  //   window.webkitRTCSessionDescription ||
  //   window.msRTCSessionDescription;

  // var pc = new peerConnection({
  //   iceServers: [
  //     {
  //       urls: ['stun:video.ws-chat.de'],
  //       username: 'somename',
  //       credential: 'somecredentials'
  //     }
  //   ]
  // });

  // pc.ontrack = function(obj) {
  //   document.getElementById('users').html = '';
  //   var vid = document.createElement('video');
  //   vid.setAttribute('class', 'video-small');
  //   vid.setAttribute('autoplay', 'autoplay');
  //   vid.setAttribute('id', 'video-small');
  //   document.getElementById('users').appendChild(vid);
  //   vid.srcObject = obj.streams[0];
  // };

  // navigator.mediaDevices
  //   .getUserMedia({ video: true, audio: true })
  //   .then(function(stream) {
  //     var video = document.createElement('video');
  //     video.srcObject = stream;
  //     for (const track of stream.getTracks()) {
  //       pc.addTrack(track, stream);
  //     }
  //   }, error);

  // socket.on('add-user', function(data) {
  //   const id = data.user;
  //   createOffer(id);

  //   const el = document.createElement('div');
  //   el.setAttribute('id', id);
  //   el.innerHTML = id;
  //   // document.getElementById('users-container').appendChild(el);
  // });

  // socket.on('remove-user', function(id) {
  //   const div = document.getElementById(id);
  //   //   pc.close();

  //   if (div) {
  //     // document.getElementById('users-container').removeChild(div);
  //   }
  // });

  // socket.on('offer-made', function(data) {
  //   if (data.socket !== socket.id) {
  //     pc.setRemoteDescription(
  //       new sessionDescription(data.offer),
  //       function() {
  //         pc.createAnswer(function(answer) {
  //           pc.setLocalDescription(
  //             new sessionDescription(answer),
  //             function() {
  //               socket.emit('make-answer', {
  //                 answer: answer,
  //                 to: data.socket
  //               });
  //             },
  //             error
  //           );
  //         }, error);
  //       },
  //       error
  //     );
  //   }
  // });

  // socket.on('answer-made', function(data) {
  //   pc.setRemoteDescription(
  //     new sessionDescription(data.answer),
  //     function() {
  //       // if (!answersFrom[data.socket]) {
  //       //   createOffer(data.socket);
  //       //   answersFrom[data.socket] = true;
  //       // }
  //     },
  //     error
  //   );
  // });

  // function createOffer(id) {
  //   if (id !== socket.id) {
  //     pc.createOffer(function(offer) {
  //       pc.setLocalDescription(
  //         new sessionDescription(offer),
  //         function() {
  //           socket.emit('make-offer', {
  //             offer: offer,
  //             to: id
  //           });
  //         },
  //         error
  //       );
  //     }, error);
  //   }
  // }

  // function error(err) {
  //   console.warn('Error', err);
  // }
}
