// @ts-check

/**
 * @param {Object} [options]
 * @param {(...any) => void} [options.pingCallback]
 */
export const startHealthcheck = function(options = {}) {
  var wasLastPingSuccessful = true;
  var hasConfig = typeof window.__healthcheck !== 'undefined';
  var isFE = hasConfig && __healthcheck.frontend;
  if (!options.pingCallback) {
    options.pingCallback = () => {};
  }

  function fetchWithTimeout(url, timeout) {
    var FETCH_TIMEOUT = timeout;
    var didTimeOut = false;

    return new Promise(function(resolve, reject) {
      var timeout = setTimeout(function() {
        didTimeOut = true;
        reject(new Error('Request timed out'));
      }, FETCH_TIMEOUT);

      fetch(url, {
        headers: {
          'Cache-Control': 'no-cache'
        }
      })
        .then(function(response) {
          // Clear the timeout as cleanup
          clearTimeout(timeout);
          if (!didTimeOut) {
            resolve(response);
          }
        })
        .catch(function(err) {
          // Rejection already happened with setTimeout
          if (didTimeOut) return;
          // Reject with error
          reject(err);
        });
    });
  }

  function wait(seconds) {
    return new Promise(function(resolve) {
      setTimeout(resolve, seconds);
    });
  }

  function update(code) {
    var lastPing = wasLastPingSuccessful;
    wasLastPingSuccessful = code === 200;
    if (lastPing !== wasLastPingSuccessful) {
      var el = document.getElementById('ping-alert-box');

      // remove the box if it existed before
      if (el) {
        el.parentNode.removeChild(el);
      }

      if (lastPing) {
        // we went from good -> bad
        if (isFE) {
          document.querySelector('#chatForm input.form-control').disabled = true;
          document.querySelector('#typing').innerText = 'Momentan nicht erreichbar.';
        } else {
          var newEl = buildEl();
          document.body.appendChild(newEl);
        }
      } else {
        // we went from bad -> good
        if (isFE) {
          document.querySelector('#chatForm input.form-control').disabled = false;
          document.querySelector('#typing').innerText = '';
        }
      }
    }
  }

  function buildEl() {
    var newEl = document.createElement('div');
    newEl.setAttribute('id', 'ping-alert-box');
    newEl.setAttribute('class', 'callout callout-danger');

    var title = document.createElement('h4');
    title.appendChild(document.createTextNode('Verbindung zum Server verloren'));

    var text = document.createElement('p');
    text.appendChild(document.createTextNode('Versuche Verbindung wieder herzustellen...'));

    newEl.appendChild(title);
    newEl.appendChild(text);
    return newEl;
  }

  function ping() {
    fetchWithTimeout((hasConfig && __healthcheck.endpoint) || '/ping', 10000)
      .then(function(response) {
        update(response && response.status);
        try {
          response.json().then(options.pingCallback);
        } catch (err) {
          console.log(err);
          options.pingCallback();
        }
        wait(5000).then(ping);
      })
      .catch(function(err) {
        update();
        options.pingCallback({ reasonForFailure: err });
        wait(5000).then(ping);
      });
  }

  ping();
};
